import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';

import { Http, Response, RequestOptions, Headers, URLSearchParams } from '@angular/http';
import { Global } from '../global';

@Injectable({
  providedIn: 'root'
})
export class WaylensService {

  constructor(public global: Global, private http: Http) { }


  get_device_streaming(deviceId, action) {
    let body = new URLSearchParams();
    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    body.set('deviceId', deviceId);
    body.set('action', action);

    var link = this.global.baseURL + 'waylens/get_device_streaming';
    return this.http.post(link, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }

  get_device_streaming_status(deviceId) {
    let body = new URLSearchParams();
    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    body.set('deviceId', deviceId);

    var link = this.global.baseURL + 'waylens/get_device_streaming_status';
    return this.http.post(link, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }


  get_device_events_list(deviceId, range, event_type = 'Accelerate') {
    let body = new URLSearchParams();
    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    body.set('deviceId', deviceId);
    body.set('start', range.start.date + ' ' + range.start.time);
    body.set('end', range.end.date + ' ' + range.end.time);
    body.set('eventType', event_type);

    let url = 'waylens/get_device_events_list';
    if (this.global.deviceType == 'camera_sw') {
      url = 'smartwitness/get_device_events_list';
    }

    var link = this.global.baseURL + url;
    return this.http.post(link, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }

  get_device_trips_list(deviceId, start, end) {
    let body = new URLSearchParams();
    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    body.set('deviceId', deviceId);
    body.set('start', start);
    body.set('end', end);

    let url = 'waylens/get_device_trips_list';
    if (this.global.deviceType == 'camera_sw') {
      url = 'smartwitness/get_device_trips_list';
    }

    var link = this.global.baseURL + url;
    return this.http.post(link, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }

  get_device_clip_detail(deviceId, clipId) {
    let body = new URLSearchParams();
    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    body.set('deviceId', deviceId);
    body.set('clipId', clipId);

    var link = this.global.baseURL + 'waylens/get_device_clip_detail';
    return this.http.post(link, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }


  get_device_upload_clip(deviceId, range, duration?) {
    debugger;
    let body = new URLSearchParams();
    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    body.set('deviceId', deviceId);
    body.set('startTime', range.start.date + ' ' + range.start.time);
    if (range.end) {
      body.set('endTime', range.end.date + ' ' + range.end.time);
    }
    body.set('duration', duration);

    let url = 'waylens/get_device_upload_clip';
    if (this.global.deviceType == 'camera_sw') {
      
      url = 'smartwitness/get_device_upload_clip';
    }
    var link = this.global.baseURL + url;
    return this.http.post(link, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }


  
  get_device_uploaded_clips_list(deviceId) {
    let body = new URLSearchParams();
    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    body.set('deviceId', deviceId);

    var link = this.global.baseURL + 'smartwitness/get_device_uploaded_clips_list';
    return this.http.post(link, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }


  private catchError(error: Response | any) {
    console.log(error);
    return Observable.throw(error.json().error || "Server error");
  }

  private logResponse(res: Response | any) {
    // console.log("server response", res);
    // console.log("server response - parsed", res.json());
  }

  private extractData(res: Response) {
    return res.json();
  }
}
